import elections from "@/services/elections";
import errors from "@/mixins/errors";

export default {
  mixins: [errors],
  methods: {
    updateElectionConfiguration() {
      let self = this;
      elections
        .updateBallot(this.election)
        .then(() => {
          self.errors = {};
        })
        .catch(error => {
          if (error.response) {
            self.errors = error.response.data;
          }
        });
    }
  }
};