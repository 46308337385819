<template>
  <tiptap-vuetify
    :placeholder="placeholder"
    v-model="wswgData"
    :extensions="extensions"
    @blur="submit"
    :disabled="disabled"
  />
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

export default {
  name: "wswg",
  props: ["parentData", "placeholder", "disabled"],
  components: {
    TiptapVuetify
  },
  data: () => ({
    errors: {},
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    wswgData: null
  }),
  mounted() {
    this.wswgData = this.parentData;
  },
  watch: {
    parentData(newValue) {
      this.wswgData = newValue;
    }
  },
  methods: {
    submit() {
      this.$emit("wswgSubmit", this.wswgData);
    }
  }
};
</script>