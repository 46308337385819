<template>
  <v-card flat>
    <v-card-text>
      <h3 class="red--text">
        Puede modificar elementos visuales de la votación
      </h3>
      <wswgComponent
        :parentData="election.configuration_attributes.instructions"
        @wswgSubmit="wswgInstructions"
        :disabled="!canEdit"
      />

      <v-row>
        <v-col cols="12" md="9">
          <v-text-field
            v-model="
              election.configuration_attributes.verify_selection_button_text
            "
            label="Texto del botón de verificación"
            :error-messages="errorFor('verify_selection_button_text')"
            @blur="updateElectionConfiguration"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn color="red" dark>
            {{ election.configuration_attributes.verify_selection_button_text }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="9">
          <v-text-field
            v-model="
              election.configuration_attributes.verification_instructions
            "
            label="Texto de las instrucciones de verificación"
            :error-messages="errorFor('verification_instructions')"
            @blur="updateElectionConfiguration"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <h2 class="red--text">
            {{ election.configuration_attributes.verification_instructions }}
          </h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="9">
          <v-text-field
            v-model="election.configuration_attributes.back_button_text"
            label="Texto del botón editar"
            :error-messages="errorFor('back_button_text')"
            @blur="updateElectionConfiguration"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn color="red" dark>
            {{ election.configuration_attributes.back_button_text }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="9">
          <v-text-field
            v-model="election.configuration_attributes.submit_button_text"
            label="Texto del botón votar"
            :error-messages="errorFor('submit_button_text')"
            @blur="updateElectionConfiguration"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn color="red" dark>
            {{ election.configuration_attributes.submit_button_text }}
          </v-btn>
        </v-col>
      </v-row>

      <v-checkbox
        v-model="election.configuration_attributes.confirm"
        label="Enviar correo de confirmación de voto"
        @click="expandOrCollapseAndUpdate"
        :disabled="!canEdit"
      ></v-checkbox>

      <v-expand-transition>
        <div v-show="expand">
          <v-text-field
            v-model="election.configuration_attributes.confirm_title"
            label="Asunto del correo de confirmación de voto"
            :error-messages="errorFor('confirm_title')"
            @blur="updateElectionConfiguration"
            :disabled="!canEdit"
          ></v-text-field>
          <wswgComponent
            :parentData="election.configuration_attributes.confirm_body"
            placeholder="Texto del correo de confirmacón de voto"
            @wswgSubmit="wswgSubmit"
            :disabled="!canEdit"
          />
        </div>
      </v-expand-transition>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('features')"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>Atrás
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('loaddata')"
      >
        Siguiente<v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import updateElectionConfiguration from "@/mixins/updateElectionConfiguration";
import wswgComponent from "@/components/wswg/wswg.vue";
import electionCanEdit from "@/mixins/electionCanEdit";

export default {
  name: "BallotConfiguration",
  mixins: [updateElectionConfiguration, electionCanEdit],
  components: {
    wswgComponent
  },
  props: ["election"],
  data: () => ({
    expand: true
  }),
  watch: {
    election() {
      this.expand = this.election.configuration_attributes.confirm;
    }
  },
  methods: {
    expandOrCollapseAndUpdate() {
      this.updateElectionConfiguration();
      this.expand = !this.expand;
    },
    getRoute(option) {
      return "/wizard/" + this.election.id + "/" + option;
    },
    wswgSubmit(data) {
      this.election.configuration_attributes.confirm_body = data;
      this.updateElectionConfiguration();
    },
    wswgInstructions(data) {
      this.election.configuration_attributes.instructions = data;
      this.updateElectionConfiguration();
    }
  }
};
</script>