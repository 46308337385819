export default {
  data: () => ({
    election: {
      status: "pending",
      name: null,
      start_at:
        new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString().substr(0, 10) +
        " " +
        new Date(Date.now() + 1000 * 60 * 60 * 24).toLocaleTimeString(),
      end_at:
        new Date(Date.now() + 1000 * 60 * 60 * 32).toISOString().substr(0, 10) +
        " " +
        new Date(Date.now() + 1000 * 60 * 60 * 32).toLocaleTimeString(),
      country_alpha2: "PE",
      organization_id: null,
      organization: {
        id: null,
        name: '',
        description: ''
      },
      timezone: "America/Lima",
      configuration_attributes: {
        election_method_id: null,
        anonymity_id: null,
        results_viewable_during: null,
        results_viewable_after: null,
        instructions: null,
        verify_selection_button_text: null,
        verification_instructions: null,
        back_button_text: null,
        submit_button_text: null,
        confirm_title: null,
        confirm_body: null
      }
    }
  })
};