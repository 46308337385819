<template>
  <v-tabs
    v-model="tab"
    color="red"
    show-arrows
    center-active
    class="mb-6"
    centered
  >
    <v-tab
      v-for="option in options"
      :key="option.id"
      :to="option.route"
      :disabled="option.disabled"
      router
    >
      {{ option.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "Wizard",
  data: () => ({
    tab: null,
    selected: null,
    options: []
  }),
  methods: {
    getOptions(currentId) {
      currentId = currentId ? "/" + currentId : "";
      let firstOption = currentId ? "/edit" : "/new";

      //Will disable all options except the first one if is mounted by the "New" event
      let isDisabled = currentId ? false : true;

      //Conditions to show election results
      let showResults = !currentId ? true : false;

      return [
        {
          id: 0,
          title: "General",
          disabled: false,
          route: "/wizard" + currentId + firstOption
        },
        {
          id: 3,
          title: "Consultas",
          disabled: isDisabled,
          route: "/wizard" + currentId + "/questions"
        },
        {
          id: 4,
          title: "Características",
          disabled: isDisabled,
          route: "/wizard" + currentId + "/features"
        },
        {
          id: 5,
          title: "Cédula",
          disabled: isDisabled,
          route: "/wizard" + currentId + "/configuration"
        },
        {
          id: 6,
          title: "Padrón",
          disabled: isDisabled,
          route: "/wizard" + currentId + "/loaddata"
        },
        {
          id: 7,
          title: "Notificar",
          disabled: isDisabled,
          route: "/wizard" + currentId + "/notification"
        },
        {
          id: 9,
          title: "Pago",
          disabled: isDisabled,
          route: "/wizard" + currentId + "/payment"
        },
        {
          id: 8,
          title: "Resultados",
          disabled: showResults,
          route: "/wizard" + currentId + "/results"
        }
      ];
    }
  },
  mounted() {
    this.options = this.getOptions(this.$route.params.id);
  }
};
</script>