import election from "@/mixins/election";
import elections from "@/services/elections";

export default {
  mixins: [election],
  mounted() {
    elections.show(this.$route.params.id).then(response => {
      this.election = response.data;
    });
  },
};