export default {
  data: () => ({
    errors: {}
  }),
  methods: {
    errorFor(attribute) {
      if (this.errors["details"] != undefined) {
        if (this.errors["details"][attribute] != undefined) {
          return this.errors["details"][attribute]["errors"];
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }
};